import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'

function LoginPage() {
    const router = useRouter()
    const { loginWithRedirect, user } = useAuth0()

    if (router.query.invitation && router.query.organization) {
        loginWithRedirect({
            invitation: router.query.invitation as string,
            organization: router.query.organization as string,
            screen_hint: 'signup',
            initialScreen: 'signUp',
        })
    }

    return (
        <>
            <h1>Logging you in...</h1>
        </>
    )
}
export default LoginPage
